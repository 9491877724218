import React from "react"

import Seo from "../components/seo"
import HeroInner from "../components/heroinner"
import GetInTouchForm from "../components/getintouch_form"
// import iFastLogo from "../static/images/iFast--logo.png"

export default function ContactUs() {
  const heroTitle = "Contact Us"
  const heroImage = "/images/slider/ContactUs--Banner.jpg"
  const heroMobileImage = "/images/slider/mobile/ContactUs--Banner.jpg"
  return (
    <>
      <Seo title={heroTitle} />
      <HeroInner heroTitle={heroTitle} heroImage={heroImage} heroMobileImage={heroMobileImage} />
      <section class="c-section c-section-sm">
        <div class="container">
          <div class="row">
            <aside class="col-12 col-lg-6 col-md-6">
              <div class="sec-title light">
                <h1 class="h2 h2-sm">Get in Touch</h1>
                <p class="text">
                  If you have any questions, would like to know more about us, or if you would like
                  to book an appointment, please enter your details and we’ll be in touch to discuss
                  your needs.
                </p>
                <p>Alternatively, you can contact us directly and speak to a member of the team.</p>
              </div>

              <GetInTouchForm />
            </aside>

            <div class="col-12 col-lg-6 col-md-6 sm:mmt-6">
              <div class="innerpage--content innerpage--content-sm mt-0 pb-2">
                <h2 class="h3 h3-sm">&nbsp;</h2>
                <ul class="address">
                  <li>
                    <span class="small--title">Email:</span>{" "}
                    <a href="mailto:vikrant@apricuswealth.in">vikrant@apricuswealth.in</a>
                  </li>
                  <li>
                    <span class="small--title">Phone:</span> +91 11 40505901
                  </li>
                  <li>
                    <span class="small--title">Mobile:</span> +91 981030 2008
                  </li>
                  <li>
                    <span class="small--title">Office:</span> Flat no. 1105, 11th Floor, 1106
                    Skipper House, 22, Fire Brigade Lane, Barakhamba, Connaught Place, New Delhi -
                    110001
                  </li>
                </ul>

                {/* <img
                  src={iFastLogo}
                  alt="iFAST Corporation Ltd"
                  class="iFast--logo img-thumbnail mb-2"
                />

                <p class="mb-2">
                  Kunal Bhatia of Apricus Wealth Advisors is an Investment Adviser of iFAST Global
                  Markets, the investment advisory division of iFAST Financial India. iFAST is a
                  corporate SEBI Registered Investment Adviser. Details of iFast Financial India:
                </p> */}

                <p class="mb-4">
                  <strong>Apricus Wealth Investment Managers LLP</strong>
                  <br />
                  Type of Registration: Non-Individual
                  <br />
                  Registration number: INA000017657 (Validity: Perpetual)
                </p>

                <p class="mb-4">
                  <strong>Complete address with telephone no:</strong>
                  <br />
                  Apricus Wealth Investment Managers LLP
                  <br />
                  Flat no. 1105, 11th Floor, 1106 Skipper House, 22, Fire Brigade Lane, Barakhamba, Connaught Place
                  <br />
                  New Delhi - 110001
                  <br />
                  (+91-98103-02008)
                </p>

                <p>
                  <strong>Contact details of the Principal Officer:</strong>
                  <br />
                  Name: Vikrant Gupta
                  <br />
                  Contact number - +91-98103-02008
                  <br />
                  Email id - <a href="mailto:vikrant@apricuswealth.in">vikrant@apricuswealth.in</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
